import React from 'react';

function Contact() {
  return ( <div className='p-10 text-left w-1/2'>
    <h2 className='m-auto text-5xl text-center'>Contact Us</h2>
    <br></br>
    <p>WowRings.co is being developed by Funtika LTD. We are always happy to get feedback, question and suggestions.</p>
      <p>Please contact us :</p>
    <p className='font-bold'>Email: <span>funtika.support@gmail.com</span></p>
  
  </div> );
}

export default Contact;