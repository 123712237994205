
import React,{ useState , useEffect } from "react";
import avatarDraft from '../images/noun-profile-1326002-FFFFFF.png';
import domtoimage from "dom-to-image";

import iconborder from '../images/noun-ring-2596315-FFFFFF.png';
import icontext from '../images/noun-text-1818212-FFFFFF.png';
import iconfilter from '../images/noun-filters-3552526-FFFFFF.png';

import AdSense from 'react-adsense';

export default function Maker(){
  const [borderThickness , setBorderThickness] = useState(10);
  const [borderOffset , setBorderOffset] = useState(0);
  const [borderOpacity , setBorderOpacity] = useState(80);

  const [borderStyle , setBorderStyle] = useState("gradient");

  const [borderFlatColor , setBorderFlatColor] = useState("#cdcdcd");
  const [borderGradientStartColor , setBorderGradientStartcolor] = useState("#ee0979");
  const [borderGradientEndColor , setBorderGradientEndcolor] = useState("#ff6a00");
  const [borderGradientRotate , setBorderGradientRotate] = useState(10);
  const [borderImage , setBorderImage] = useState();

  const [textContent , setTextcontent ] =useState("");
  const [textRadius , setTextRadius ] = useState(100);
  const [textRotate , setTextRotate ] = useState(0);
  const [textSize , setTextSize ] = useState(16);
  const [textSpace , setTextSpace ] = useState(20);
  const [textColor , setTextColor ] = useState("#cdcdcd");
  const [textFont , setTextFont ] =  useState("Lato");

  const [ filterColor , setFilterColor ] = useState("#000000");
  const [ filterOpacity , setFilterOpacity ] = useState(0);

  useEffect(() => {
    let ProfileBorder = document.getElementById('profileBorder');
    let ProfileBorderGradient = document.getElementById('profileBorderGradient');
    let ProfileBorderImage = document.getElementById('profileBorderImage');

    if (borderStyle === "flatColor") {
      ProfileBorder.style.display = 'block';
      ProfileBorderGradient.style.display = 'none';
      ProfileBorderImage.style.display = 'none';

      ProfileBorder.style.borderStyle  = "solid";

      let thicknesss = borderThickness / 100.0 * ProfileBorder.offsetWidth / 2 + 'px';

      ProfileBorder.style.borderWidth = thicknesss;
      ProfileBorder.style.opacity = borderOpacity / 100.0;

      let width = (1 - borderOffset / 100) * 450 + 'px';
      ProfileBorder.style.width = width;
      ProfileBorder.style.height = ProfileBorder.style.width;
      ProfileBorder.style.borderColor = borderFlatColor;           
    }
    if (borderStyle === "gradient") {
      ProfileBorder.style.display = 'none';
      ProfileBorderGradient.style.display = 'block';
      ProfileBorderImage.style.display = 'none';

      let ProfileBorderGradientCircle = document.getElementById('profileBorderGradientCircle');
      ProfileBorderGradientCircle.style.strokeWidth = 
      `${borderThickness / 100.0 * ProfileBorderGradientCircle.r.baseVal.value * 2}`;
      //console.log(ProfileBorderGradientCircle.style.strokeWidth);
      //console.log(typeof ProfileBorderGradientCircle.r.baseVal.value);
      //ProfileBorderGradientCircle.style.r = `${(1 - borderOffset / 100.0) * 450}`;
      ProfileBorderGradientCircle.setAttribute('r',`${(1 - borderOffset / 100.0) * 225}`);
      // console.log(`${(1 - borderOffset / 100.0) * 450}`);
      //console.log(ProfileBorderGradientCircle.r.baseVal.value);
      let ProfileBorderGradientBox = document.getElementById('profileBorderGradientBox');
      ProfileBorderGradientBox.style.transform = `rotate(${borderGradientRotate / 100.0 *360}deg)`;

    }
    if (borderStyle === "image") {
      ProfileBorder.style.display = 'none';
      ProfileBorderGradient.style.display = 'none';
      ProfileBorderImage.style.display = 'block';

      let ProfileBorderImageCircle = document.getElementById('profileBorderImageCircle');
      ProfileBorderImageCircle.style.strokeWidth = 
      `${borderThickness / 100.0 * ProfileBorderImageCircle.r.baseVal.value * 2}`;
      ProfileBorderImageCircle.setAttribute('r',`${(1 - borderOffset / 100.0) * 225}`);
    }

  },[borderThickness,borderOffset,borderOpacity,borderStyle,borderGradientRotate,
    borderFlatColor,borderGradientEndColor,borderGradientStartColor,
    borderImage]);

  return(
    <div className="h-full bg-gray-400">
      <br></br>
      <h1 className="text-xl font-bold text-gray-800">WowRings - Profile Picture Maker App</h1>
      <p>
  
        <span className="text-green-300">Your privacy is protected.</span>

      </p>

      <div className="flex flex-col justify-center p-4 lg:flex-row">
        <div className="hidden lg:flex lg:items-center">

<AdSense.Google
  client='ca-pub-9622703609220982'
  slot='7741521310'
  style={{ display: 'block',width:'300px'}}
            layout='in-article'
 
  format='fluid'
/>
        </div>
        <div className = " flex profileMadebyYou bg-gray-200 rounded-[30px] \
        lg:w-[528px] lg:h-[854px] m-3 \
        flex-col justify-center items-center">
          <button className="w-[164px] h-[36px] rounded-[18px] bg-yellow-400 flex items-center justify-center"
          onClick = {e => {
            let imageE = document.getElementById('ImageSelector');
            imageE.click();
          }}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
            INSERT PHOTO</button>
          <input type = "file" accept = "image/*" id = "ImageSelector" className = "hidden"
          onChange={e =>{
            let imageE = document.getElementById('ImageSelector');
            let image = imageE.files[0];
            console.log(image);
            let profileAvatar = document.getElementById('profileAvartar');
            profileAvatar.src = URL.createObjectURL(image);
            console.log("photo:",URL.createObjectURL(image));
          }}></input>
          <br></br>
          <div id ="profile" className="w-[450px] h-[450px] rounded-[225px] bg-gray-100 \
          shadow-xl">
            <div  className="w-full h-full relative">

              <img id = "profileAvartar"  alt ="" style={{objectFit:"cover",objectPosition:"50% 0px"}} src = {avatarDraft}
              className="rounded-[50%] w-full h-full"></img>

              <div id = "profileBorder"  className="profileWidget absoulute w-full h-full rounded-full
              bg-transparent">                               
              </div>
              <div id="profileBorderGradientBox" 
              className="profileGradientWidget absolute w-full h-full rounded-full">
              <svg id = "profileBorderGradient" 
              className="profileWidget absolute w-full h-full rounded-full hidden" 
              style={{opacity:borderOpacity / 100.0}}>
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                  <stop id="gradientStartColor" offset="5%" stopColor={borderGradientStartColor}></stop>
                  <stop id="gradientEndColor" offset="100%" stopColor={borderGradientEndColor}></stop>
                </linearGradient>
                <circle id="profileBorderGradientCircle" r="202.5" cx="225" cy="225" className="external-circle" 
                strokeWidth="45" fill="none" stroke="url(#linearColors)" 
                ></circle>
              </svg>
              </div>            
              <svg id="profileBorderImage" 
              className="profileWidget absoulute w-full h-full rounded-full hidden"
              style={{opacity:borderOpacity / 100.0}}>
              <defs>
                <pattern id="image" x="-200%" y="-150%" height="400%" width="400%"
                        viewBox="0 0 450 450">
                  <image  className="rounded-full w-full h-full" style={{objectFit:"fill"}}
                  xlinkHref={borderImage}></image>
                </pattern>
              </defs>  
              <circle id="profileBorderImageCircle" className="medium" cx="50%" cy="50%" r="225" 
              fill="none" stroke="url(#image)" strokeWidth="45" />
              </svg> 

              <svg id="profileRoundText"
              className=" absolute w-full h-full rounded-full"
              style={{
                transform:`rotate(${textRotate}deg)`,
              }}>
                <defs>
                  <path id = "textCirclePath1" 
                  d={`M${225 - textRadius},225
                  a${textRadius},${textRadius} 0 1,0 ${2 * textRadius },0
                  a${textRadius},${textRadius} 0 1,0 ${- 2 * textRadius },0`} />
                </defs>
                  <text 
                  // style={{
                  //   transform:`rotate(${textRotate / 100.0 *360}deg)`,
                  // }}                  
                  fill={textColor}>
                  <textPath 
                  id="roundTextContent"
                  style={{
                    fontColor:textColor,
                    fontSize:textSize,
                    letterSpacing:textSpace,   
                    fontFamily : textFont,                
                  }}
                  xlinkHref = "#textCirclePath1">{textContent}</textPath>
                </text>
              </svg>
              <div id = "profileFilter" 
              style={{
                backgroundColor : filterColor,
                opacity : filterOpacity / 100.0, 
              }} 
              className="profileWidget absoulute w-full h-full rounded-full">                               
              </div>
            </div>
          </div>
          <br></br>
          <button 
          className="w-[164px] h-[36px] rounded-[18px] bg-green-400 flex justify-center items-center"
          onClick={(e) => {
            console.log("ok");
            var node = document.getElementById('profile');
            domtoimage.toPng(node)
            .then((dataUrl) => {
              const fileName = dataUrl.split('/').pop();
              var el = document.createElement("a");
              el.setAttribute("href", dataUrl);
              el.setAttribute("download", fileName);
              document.body.appendChild(el);
              el.click();
              el.remove();
            });
          }}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg>
            SAVE IMAGE</button>
        </div>



        <div className = "profileMakingSetting bg-white rounded-[30px] \
        lg:w-[400px] lg:h-[854px] m-3">
          <div className="bg-gray-500 rounded-t-[30px]">
            <ul className="navbar pt-4
            flex flex-row justify-center space-x-2">
              <li className="navbarItem selected" onClick={e => {
                document.querySelectorAll(".navbarItem").forEach(item => {
                  item.classList.remove("selected");
                });
                //console.log(document.querySelectorAll(".navbarItem"));
                document.getElementById('borderSetting').style.display = "block";
                e.target.classList.add("selected");
                //document.getElementById('borderSetting').classList.add('selected');
                document.getElementById('TextSetting').style.display = "none";
                //document.getElementById('TextSetting').classList.remove('selected');
                document.getElementById('FilterSetting').style.display = "none";
                //document.getElementById('FilterSetting').classList.remove('selected');
              }}>
                <img alt="border"
                src={iconborder}
                style = {{
                  backgroundColor : "transparent",
                  margin : "auto",
                }}></img>
                Border</li>
              <li className="navbarItem" onClick={e => {
                document.querySelectorAll(".navbarItem").forEach(item => {
                  item.classList.remove("selected");
                });
                document.getElementById('borderSetting').style.display = "none";
                //document.getElementById('borderSetting').classList.remove('selected');
                document.getElementById('TextSetting').style.display = "block";
                e.target.classList.add("selected");
                //document.getElementById('TextSetting').classList.add('selected');
                document.getElementById('FilterSetting').style.display = "none";
                //document.getElementById('FilterSetting').classList.remove('selected');
              }}>
                <img alt="border"
                src={icontext}
                style = {{
                  backgroundColor : "transparent",
                  margin : "auto",
                }}></img>
                Text</li>
              <li className="navbarItem" onClick={e => {
                document.querySelectorAll(".navbarItem").forEach(item => {
                  item.classList.remove("selected");
                });
                document.getElementById('borderSetting').style.display = "none";
                //document.getElementById('borderSetting').classList.remove('selected');
                document.getElementById('TextSetting').style.display = "none";
                //document.getElementById('TextSetting').classList.remove('selected');
                document.getElementById('FilterSetting').style.display = "block";
                e.target.classList.add("selected");
                //document.getElementById('FilterSetting').classList.add('selected');
              }}>
                <img alt="border"
                src={iconfilter}
                style = {{
                  backgroundColor : "transparent",
                  margin : "auto",
                }}></img>
                Filter</li>
            </ul>
          </div>
          <div className="h-[794px] rounded-b-[30px]">
          <div id="settingPanel" className="h-[764px]">
            <div id="borderSetting">
              <div className="settingPanelWidget">
                <p className="settingLabel">THICKNESS</p>
                <input type="range" className="slider" min="0" max="100" defaultValue="20" onChange = {e => {
                  setBorderThickness(e.target.value);
                }}></input>
                <p className="settingLabel">OFFSET</p>
                <input type="range" className="slider" min="0" max="100" defaultValue="0" onChange = {e => {
                  setBorderOffset(e.target.value);
                }}></input>
                <p className="settingLabel">OPACITY</p>
                <input type="range" className="slider" min="0" max="100" defaultValue="80" onChange = {e => {
                  setBorderOpacity(e.target.value);
                }}></input>
              </div>
              <div className="settingPanelWidget">
                <div id="borderStyleSelector" className="flex flex-wrap space-x-4 justify-center pb-10">
                  <div>
                    <input type="radio" name="borderstyle" className="mr-1" 
                    id = "borderStyleGradient" checked={borderStyle === "gradient"} onChange={e => {
                        setBorderStyle("gradient");

                document.getElementById('gradientStyleSetting').style.display = "block";
                document.getElementById('flatStyleSetting').style.display = "none";
                document.getElementById('imageStyleSetting').style.display = "none";
              }}/>
                    <label>GRADIENT</label>
                  </div>
                  <div>
                    <input type="radio" name="borderstyle" checked = {borderStyle === "flatColor"}
                    className="mr-1" onChange={e => {
                      setBorderStyle("flatColor");
                document.getElementById('gradientStyleSetting').style.display = "none";
                document.getElementById('flatStyleSetting').style.display = "block";
                document.getElementById('imageStyleSetting').style.display = "none";
              }}/>
                    <label>FLAT COLOR</label>
                  </div>
                  <div>
                    <input type="radio" name="borderstyle" checked = {borderStyle === "image"}
                    className="mr-1" onChange={e => {
                      setBorderStyle("image");
                      
                document.getElementById('gradientStyleSetting').style.display = "none";
                document.getElementById('flatStyleSetting').style.display = "none";
                document.getElementById('imageStyleSetting').style.display = "block";
              }}/>
                    <label>IMAGE</label>
                  </div>
                </div>
                <div id="borderContentSeletor">
                  <div id="gradientStyleSetting">
                    <div id="templateGradientArray"></div>
                    <div id="customGradientColor" className="text-center">
                      <p>CHANGE COLOR</p>
                      <input type="color" id="gradientStartColot"  onChange={e => {
                        setBorderGradientStartcolor(e.target.value);
                      }}
                      defaultValue="#ee0979" className="w-1/2"></input>
                      <input type="color" id="gradientEndColor" onChange={e => {
                        setBorderGradientEndcolor(e.target.value);
                      }}
                      defaultValue="#ff6a00" className="w-1/2"></input>
                    </div>
                    <div id="gradientRotate">
                    <p className="settingLabel">GRADIENT ROTATE</p>
                    <input type="range" className="slider" min="0" max="100" defaultValue="10" onChange = {e => {
                      setBorderGradientRotate(e.target.value);
                    }}></input>
                    </div>
                  </div>
                  <div id="flatStyleSetting" className="hidden">
                    <div id="templateFlatArray"></div>
                    <div id="customFlatColor" className="text-center">
                      <p>EDIT COLOR</p>
                      <input type="color" id="customFlat" onChange={e => {
                        setBorderFlatColor(e.target.value);
                      }}
                      defaultValue="#cdcdcd" className="w-1/2"></input>
                    </div>
                  </div>
                  <div id="imageStyleSetting" className="hidden">
                    <button className="rounded-full p-2 m-auto bg-yellow-400 w-4/5 flex justify-center items-center"
                    onClick={(e) => {
                      document.getElementById('BorderImageSelector').click();
                    }}>
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                      INSERT IMAGE AS BORDER</button>
                    <input type = "file" accept = "image/*" id = "BorderImageSelector" className = "hidden"
                    onChange={e =>{
                      let imageE = document.getElementById('BorderImageSelector');
                      let image = imageE.files[0];
                      setBorderImage(URL.createObjectURL(image));
                    }}></input>
                  </div>
                </div>
              </div>
            </div>
            <div id="TextSetting" className="settingPanelWidget hidden">
              <p>INSERT CIRCULAR TEXT</p>
              <textarea 
              id="textContent" placeholder="eg:#nature Love"
              className=" w-full p-2" rows="5" style={{resize:"none"}}
              onChange = {e => {
                setTextcontent(e.target.value);
              }}></textarea>
              <p className="settingLabel">TEXT RADIUS</p>
                <input type="range" className="slider" min="0" max="225" value={textRadius} onChange = {e => {
                  setTextRadius(e.target.value);
                }}></input>
              <p className="settingLabel">TEXT ROTATE</p>
                <input type="range" className="slider" min="0" max="360" value={textRotate} onChange = {e => {
                  setTextRotate(e.target.value);
                }}></input>
              <p className="settingLabel">TEXT SIZE</p>
                <input type="range" className="slider" min="0" max="100" value={textSize} onChange = {e => {
                  setTextSize(e.target.value);
                }}></input>
              <p className="settingLabel">LETTER SPACING</p>
                <input type="range" className="slider" min="0" max="100" value={textSpace} onChange = {e => {
                  setTextSpace(e.target.value);
                }}></input>
                <br />
                <label>TEXT COLOR</label><br/>
                <input type="color" id="textColor" 
                className="w-1/2"
                value={textColor}
                onChange = {e => {
                  setTextColor(e.target.value);
                }
                }></input>

                <br />
                <label>Google Font</label><br></br>
                <select 
                className = "w-1/2"
                onChange={e => {
                  setTextFont(e.target.value);
                }}>
                  <option>Rajdhani</option>
                  <option>Lato</option>
                  <option>Tapestry</option>
                </select>
              
            </div>
            <div id="FilterSetting" className="settingPanelWidget hidden">
              <div id="filterTemplateArray">
                  <label>CHOOSE FILTER</label>
                  <div>
                    <label>choose filter color</label><br></br>
                    <input type="color" id="filterColor" 
                    className="w-1/2"
                    value={filterColor}
                    onChange = {e => {
                      setFilterColor(e.target.value);
                    }
                    }></input>
                    <br></br>
                    <label>choose filter opacity</label><br></br>
                    <input type="range" className="slider" min="0" max="100" 
                    value={filterOpacity} 
                    onChange = {e => {
                      setFilterOpacity(e.target.value);
                    }}></input>
                  </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}