import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Contact from './pages/Contact';
import Main from './pages/Main';
import Legal from './pages/Legal';
import Tutorial from './pages/Tutorial';
import Header from './components/Header';
import Footer from './components/Footer';


function App() {
  return (

      <Router>
        <div className="App">
        <Header></Header>

          <Route exact path = "/" component  = {Main}></Route>
          <Route path = "/Contact" component  = {Contact}></Route>
          <Route path = "/Legal" component  = {Legal}></Route>
          <Route path = "/Tutorial" component  = {Tutorial}></Route>

        <Footer></Footer>
        </div>
      </Router>
  );
}

export default App;
