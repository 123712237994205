import React from 'react';
import Maker from '../components/Maker';
import privacy from '../images/noun-privacy-3780470-FFFFFF.png'
import five from '../images/wowrings_before_after.png'
import two from '../images/circular_text.png';
import three from '../images/3_faces.png';
import google_play_badge from '../images/google-play-badge.png';
import appstore_badge from '../images/appstore_badge.png';
import mobile_screens from '../images/mobile_screens.png';
import {Link} from 'react-router-dom'
function Main() {
  return (<div className='py-5'>
    
        <div className='flex flex-col md:flex-row justify-center items-center px-20 '>
      <div className='max-w-[500px]'>
      <p className='text-6xl font-bold'>WowRings</p>


      </div>
 
    </div>
    <div className='border-b border-gray-500 flex justify-center mx-20 py-5'>
      <img alt='Privacy' src={privacy} className="h-full"></img>
      <p>

        <span className="text-green-600"> Your privacy is protected and safe.<br/> </span>
  
        <span className="font-bold"> We don't collect your image data.</span>
 
       
      </p>
    </div>

    <div className='flex flex-col md:flex-row justify-center items-center px-20 '>
      <div className='max-w-[500px]'>
      <p className='text-3xl font-bold'>Make A brand new Rounded Ring Profile Picture With Custom Border And Text</p>
      <br></br>
      <p>Suprise your friends with WowRings catching eye frames. Create a ring profile picture with a custom rounded border and text around in seconds.

          Get more attention in social networks by using colorful border around your profile picture.
        
        Use the circular text in your campaign for promoting a business or product or for recruiting talent employee.</p>

      </div>
      <img alt="" src={five} className="w-1/2 max-w-[500px]"></img>
    </div>

    <div className='bg-gray-400 h-35 flex justify-center items-center' >
      <a className=' w-1/3 p-4' href="https://apps.apple.com/US/app/id1615792510"> 
     <button>
        <img src={appstore_badge}></img>  
    </button>

     </a>
      <a   className='rounded-full w-1/3 p-4 bg-gradient-to-r from-pink-800 to-orange-500 '

  
        href='#app'> Open WowRings Profile Picture Maker app
        
        </a>


      <a className=' w-1/3 p-4' href="https://play.google.com/store/apps/details?id=com.funtika.wowrings"> 
     <button>
        <img src={google_play_badge}></img>  
    </button>

     </a>
    </div>



    <div className='px-20 text-left lg:max-w-[800px] m-auto'>
      <p className='pt-20 pb-10 text-4xl text-gray-600 text-center font-bold' id='about'>About Us</p>
      <p>WowRings is the best web and mobile application for making a circular profile picture with a custom border or text around it. Checkout ideas for using our ring profile picture maker app.</p>
      <br></br>
      <p className='text-4xl '>#1. Easy creation of custom rounded border on profile picture</p>
      <img className='m-auto' alt=''
      src={three}></img>
      <p className='py-10'>It's so fun and cool to add a border around profile picture. You will look more prominent on social media by just adding amazing circular border to your photo. Imagine yourself scrolling in Instagram, your eyes will easily get attracted to a profile picture that has a ring border because it is eye-catchy.
        The ring border is excellent for branding. Use the border color to express emotions blue for tranquility, red for passion, you get the idea...</p>

      <p className='py-10 text-4xl'>#2. Circular text around your profile picture</p>
      <img className='m-auto' alt=''
      src={two}></img>
      <p className='py-10'>Promote a business or website or use a hashtag or any word curved with your rounded profile picture. It's great way to make your social media network aware of your campaign and it also looks good.
      One of our clients used the circular text around the profile image to promote a hiring position for his local business. he had Great success!</p>

     <p className='text-4xl'>#3. Select Rings from our mobile app artwork collection</p>
       <img className='m-auto' alt=''
      src={mobile_screens}></img>
      <p className='py-10'>Download Wowrings from Google Play or Appstore and select your best looking broder frame for your profile image. All rings were made by a dedicated artist.</p>
      <br></br>
      <p className='text-4xl'>#4. Secured Privacy. WowRings app doesn't upload any image data to our servers.</p>
      <p className='py-10'>We understand the concerns of users about thier privacy while uploading their face photo on their mobile or internet. We believe in creating appilcations which sercure the user privacy . In order to keep your image safe we will only proccess your images on your own browser thus we enable offline proccessing of the images.</p>
    </div>
    <div id="app">
      <Maker ></Maker>
    </div>
  </div> );
}

export default Main;