import React from 'react';
import {Link} from 'react-router-dom';
function Footer() {
  return ( 
    <footer className='flex flex-col items-center'>
      <div>
        <a href="https://docs.google.com/document/d/1dHFP8Y7NaPKy8et1O3JGTBjKJyDsZt1R/edit?usp=sharing&ouid=115852373331397188053&rtpof=true&sd=true" className='underline'>Privacy Policy</a>

        <label> | </label>
        <Link to="/Contact" className='underline'>Contact Us</Link>
      </div>
      <div>Copyright © 2022 Funtika LTD All rights reserved.</div>
    </footer>
   );
}

export default Footer;