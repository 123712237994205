import React from 'react';

import Tutorial1 from '../images/Tutorial1.png';
import insert_photo from '../images/insert_photo.png';
import privacy from '../images/noun-privacy-3780470-FFFFFF.png'
import options from '../images/app_options.png'
import guide_profile_image from '../images/guide_profile_image.png'
function Tutorial() {
  return ( <div className='p-20 text-left w-1/2' id='tutorial'>
    <p className='text-center text-5xl font-bold'>Tutorial on How to use WowRings Profile Picture Maker</p>

    <p className='text-2xl'>It's Super Easy! make astonishing border to your profile picture in Seconds. Here are the steps:</p>


    <p className='text-3xl font-bold'>1. Open WowRing app</p>
      <img className='m-auto' alt=''
      src={Tutorial1}></img>
    <p>Click on the "Open WowRings Profile Picture Maker app" button or download the profile picture maker from your mobile appstore.</p>
 
    <p className='text-3xl font-bold'>2. Insert your photo</p>

    <p>Click the “Insert Photo” yellow button. Select a photo for your circular profile picture maker.</p>
      <img className='m-auto' alt=''
      src={insert_photo}></img>
    <div className=' flex justify-center mx-70 py-25'>

     <span className="text-green-600"> Your privacy is protected and safe. The app is doing all the magic in your local browser without uploading your private image<br/> </span>

 
       
  
    </div>
    <p className='text-3xl font-bold'>3. Set your border properties</p>
       <img className='m-auto' alt=''
      src={options}></img>
    
    <p>Adjust your profile picture border:</p>

  <li>Change the ring border Color and transparency. You can also change the ring thickness and offset.</li>
  <li>Insert Circular Text for your campaign or status.</li>
  <li>Apply photo filters Text for example blackwhite filter</li>
 
    <p className='text-3xl font-bold'>5. Save the image</p>
      <p>Save the image on your pc/mobile directory</p>
    <p className='text-3xl font-bold'>6. Update your profile photo in any social media</p>
           <img className='m-auto' alt=''
      src={guide_profile_image}></img>
        <p>Go to your social network search for setting and replace your old photo with the new eye-catching profile photo from WowRings!</p>

  </div> );
}

export default Tutorial;